$primary: #fc105b;
$link: #fc105b;

$input-radius: 32px;

@import '~bulma/bulma';
@import '~bulma-pageloader';

// $ionicons-font-path: "~ionicons/dist/fonts";
// @import "~ionicons/dist/scss/ionicons";

// @import url('https://fonts.googleapis.com/css?family=Montserrat');
// @import url('https://fonts.googleapis.com/css?family=Ubuntu');

@import './variables';
@import './mixins';
@import './flex';
@import './spacing';


.image-upload {
  margin-bottom: 20px;
}

.custom-file-upload {
  background-color: $primary;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
}

.custom-file-upload span {
  font-size: 16px;
  font-weight: 500;
}


.no-click-event{
  pointer-events: none;
}

.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.react-select-error div {
  border-color: #f81942;
}

.react-select-error:hover div {
  border-color: #f81942;
}


.down {
  transform: rotate(45deg);
}

* {
  font-family: 'Ubuntu', sans-serif !important;
  box-sizing: inherit;
}

.line-break {
  white-space: pre-line;
}

.string-value{
  word-wrap: break-word;
  max-width: 400px;
  display: inline-block;
}

.log-id{
  max-width: 100px;
  word-wrap: break-word;
}

.table__wrapper {
  overflow-x: auto;
}

.has-pointer {
  cursor: pointer;
}

.icon {
  &.is-small {
    font-size: 1rem;
  }

  &.is-medium {
    font-size: 2rem;
  }

  &.is-large {
    font-size: 3rem;
  }
}

.lazy {
  filter: blur(1.5rem);
}

.like-btn {

  transition: .2s ease-out;

  &:active {
    transform: scale(1.2);
  }

}

.level-item {
  margin-top: auto;
}

.media {
  padding-bottom: 12px;
}

.required:after {
  color: red;
  content: " *";
}

.column:last-child {
  margin-bottom: 25px;
}

.control {
  margin-bottom: 0.75rem;
}

.flex {
  display: flex;
}

.v-center {
  display: flex;
  align-items: center
}

.tag-information {
  color: white;
  background-color: red;
  padding: 3px;
  width: 108px;
  text-align: center;
  border-radius: 6px;
}

.navbar-start{
  flex: 1;
}

.navbar-start > a:last-child{
  margin-left: auto;
}

.cell-day-button {
  display: flex;
  flex-direction: row;
  height: 58px;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-color: #e9e9e9;
  border-top-width: 0.5px;
  border-bottom-width: 0.5px;
  border-right-width: 0px;
  border-left-width: 0px;
  margin-bottom: 10px;
}

.dropdown-item{
  &:hover{
    cursor: pointer;
  background-color: #e6e6e6 !important;
}
}

.questionnaire-block{

  border-bottom: 1px solid #e6e6e6;

  &__line{
    border-bottom: 1px solid black;
  }

  &__answer{
    position: relative;


    &__remove-icon{
      position: absolute;
      top: 2px;
      right: 10px;

      & img{
        width: 20px;
        height: 20px;
      }
      &:hover{
        cursor: pointer;
      }
    }
  }


  position: relative;

  &__remove-icon{
    position: absolute;
    top: 5px;
    right: 10px;

    & img{
      width: 20px;
      height: 20px;
    }
    &:hover{
      cursor: pointer;
    }
  }
}

.remove-questionnaire-block{

}

.day-view {
  display: flex;
  width: 20px;
  height: 30px;
  border-radius: 30px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.react-calendar {
  border: none !important;

  &__marked-date{
    position: relative;
    &:after{
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      background-color: #53da53;
      bottom: 10px;
      border-radius: 50%;
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 2em .5em;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &__month-view {
    line-height: 0.3rem !important;

    &__days__day {
      font-size: 22px !important;
    }
  }

  &__tile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 30px;
    width: 50px;
    height: 50px;

    &--now{
    background-color: initial !important;

      &:hover:not(.react-calendar__tile--active){
        background-color: #e6e6e6 !important;
      }
    }

    &--hasActive {
      background: rgb(219, 56, 108) !important;
      border-radius: 30px;
    }

    &--active {
      background: #f81942 !important;
      color: white;
      border-radius: 30px;
    }
  }

  &__navigation{
    display: flex;
    &__label{
      font-size: 0.75em !important;
    }

    &__arrow{
      font-size: 25px !important;
    }
  }


}


.appointment-item {
  border-color: #dedede;
  border-style: solid;
  border-top-width: 0.6px;
  border-bottom-width: 0.6px;
  border-left-width: 0;
  border-right-width: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.is-plus {
  background-color: #11e158;
  width: 22px;
  height: 22px;
  border-radius: 30px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-edit {
  background-color: #00b7f3;
  width: 22px;
  height: 22px;
  border-radius: 30px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}


.timeslots{
  max-height: 80vh;
  overflow-y: scroll;
}
